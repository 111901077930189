/*
  Плагин для решения проблемы со стилями для состояния :focus.
  По умолчанию стили для :focus применяются и при клике мышкой.
  Так как в большинстве случаев нужно чтобы стили применялись
  только при выделении через Tab, плагин при клике мышкой добавляет элементу body
  класс using-mouse для того чтобы можно было отключать стили focus

  The default outline styling, for greatest accessibility.
  :focus {
    outline: #08f auto 2px;
  }

  When mouse is detected, ALL focused elements have outline removed.
  body.using-mouse :focus {
    outline: none;
  }
 */
const task = () => {
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse');
  });

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', (event) => {
    if (event.code === 'Tab') {
      document.body.classList.remove('using-mouse');
    }
  });
};

export default function (): void {
  window.onNuxtReady(task);
}
