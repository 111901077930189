import type { Context } from '@nuxt/types';
import { YA_METRIKA_ID, YA_METRIKA_ID_RECIPE, YA_METRIKA_ID_HEALTH } from '../constants/config';
import { sendYandexMetrika, sendAnalyticsSnowPlow, sendCustomYandexMetrika } from '~/utils/sendAnalytics';
import type { YandexMetrikData } from '~/types/analytics/yandexMetrika';
import { snowplowAnalytics } from '~/types/analytics/snowplow';
import { isAnyRecipePage, isPreviewPage, isHealthPath } from '~/router';

export default function ({ route, $logger }: Context, inject: any): void {
  const log = $logger.withScope('📈 data-layer');

  inject('sendAnalyticsSnowPlow', (analyticsParams: snowplowAnalytics) => {
    // для превью не отправляем аналитику
    if (isPreviewPage(route.name)) { return; }

    log('sendAnalyticsSnowPlow', analyticsParams);
    sendAnalyticsSnowPlow(analyticsParams);
  });

  inject('sendYandexMetrika', (analyticsParams: YandexMetrikData) => {
    // для превью не отправляем аналитику
    if (isPreviewPage(route.name)) { return; }

    const id = isAnyRecipePage(route.name) ? YA_METRIKA_ID_RECIPE
      : isHealthPath(route.path) ? YA_METRIKA_ID_HEALTH
        : YA_METRIKA_ID;
    log('sendYandexMetrika', id, analyticsParams);
    sendYandexMetrika(id, analyticsParams);
  });

  inject('sendCustomYandexMetrika', (params: YandexMetrikData) => {
    const id = isAnyRecipePage(route.name) ? YA_METRIKA_ID_RECIPE
      : isHealthPath(route.path) ? YA_METRIKA_ID_HEALTH
        : YA_METRIKA_ID;
    log('sendCustomYandexMetrika', id, params);
    sendCustomYandexMetrika(id, params);
  });
}
