import type { Context } from '@nuxt/types';
import {
  FETCH_QUICK_POLL,
  SET_QUICK_POLL_LOCATION,
} from '~/constants';

import { random } from '~/utils';

function getQuickPollsFromCurrentWidgets (
  currentWidgets: Array<any>,
  quickPollLocalStorage: any,
): any {
  return currentWidgets
    .filter((widget: any) => widget.type === 'quick-poll' && widget.ids && widget.ids.length)
    .map((widget: any) => {
      return widget.ids
        .map((pollId: number) => {
          return {
            id: pollId,
            // location: widget.location,
            widgetId: widget.id,
            lastViewed: Boolean(quickPollLocalStorage[pollId] && quickPollLocalStorage[pollId].lastWidgetId === widget.id),
            ...(quickPollLocalStorage[pollId] || {}),
          };
        })
        // .filter((poll: any) => !poll.lastViewed)
        .sort(sortPolls);
    });
}

function sortPolls (a: any, b: any): any {
  // сортировка по последнемму показанному
  // опросу в виджете
  if (a.lastViewed) {
    return 1;
  }
  if (b.lastViewed) {
    return -1;
  }

  // сортировка по отвеченным
  // сначала ставим не отвеченные
  if (a.answer) {
    return 1;
  }
  if (b.answer) {
    return -1;
  }

  // сортировка по просмотренным
  // сначала ставим не просмотренные
  if (a.viewed) {
    return 1;
  }
  if (b.viewed) {
    return -1;
  }

  // иначе по id по возрастанию (сначала старые - c меньшим id)
  return a.id - b.id;
}

function rotate (polls1: any, polls2: any): any {
  const commonPolls = [
    ...polls1,
    ...polls2,
  ]
  // сортируем, чтобы массивы с наименьшей длиной
  // были в начале
    .sort((a, b) => a.length - b.length);

  const done = commonPolls.reduce((result, polls, index) => {
    if (!index) {
      result.push(getPollFromWidget(polls));
      return result;
    }

    // из текущего виджета опросов
    // убираем опросы, которые попали в result
    const withoutResultPolls = polls.filter((poll: any) => {
      const resultIds = result.map((resultPoll: any) => resultPoll.id);
      return !resultIds.includes(poll.id);
    });

    result.push(
      getPollFromWidget(
        withoutResultPolls.length ? withoutResultPolls : polls,
      ),
    );

    return result;
  }, []);

  // // фильтруем на случай undefined
  // // может, например, возникнуть при
  // // отсутствии опросов в виджете
  return done.filter((item: any) => item);
}

function getPollFromWidget (polls: any): any {
  const notViewed = polls.filter((poll: any) => !poll.viewed);

  // если есть непросмотренные
  // показываем первый из них
  if (notViewed.length) {
    return notViewed[0];
  }

  // если все просмотренные
  // ищем те, на которые еще не ответил
  const notAnswered = polls.filter((poll: any) => typeof poll.answer === 'undefined');

  // и если такие есть
  // выбираем рандомно
  if (notAnswered.length) {
    return notAnswered[random(notAnswered.length - 1)];
  }

  // в противном случае - все просмотрены и отвечены
  // просто выбираем рандомно
  return polls[random(polls.length - 1)];
}

// export default ({ app: { router, store } }: Context) => {

const task = (store: Context['store'], routeName: string | null): void => {
  const { getters, commit } = store;

  const quickPollStateIds = Object.keys(store.state.quickPolls.polls.byId);

  const quickPollLocalStorage = JSON.parse(localStorage.getItem('quick-polls') as string) || {};

  // опросы на главной
  const quickPollsBetweenPosts = getQuickPollsFromCurrentWidgets(
    getters.currentBetweenPostsWidgets,
    quickPollLocalStorage,
  );
  // опросы в сайдбаре
  const quickPollsSidebar = getQuickPollsFromCurrentWidgets(
    getters.currentSidebarWidgets,
    quickPollLocalStorage,
  );
  // опросы под постом
  const quickPollsAboveComments = getQuickPollsFromCurrentWidgets(
    getters.currentAboveCommentsWidgets,
    quickPollLocalStorage,
  );

  // по условию задачи https://app.clickup.com/t/18mt95g
  // виджет быстрых опросов может находиться
  // - в правом сайдбаре
  // - в ленте постов на главной (зона виджетов, разрывающая ленту)
  // - в зонах виджетов под постом
  // и не могут быть показан один и тот же опрос (если это возможно)
  // так как невозможно на момент реализации определить
  // какие виджеты находятся на странице
  // и учитывая что одновременно опрос может находиться
  // - либо на главной и сайдбаре
  // - либо под постом и сайдбаре
  // то делаем взаимоислючающую проверку в этих парах
  function getRotateByRouteName (routeName: string) {
    const rotateByRouteName = {
      index: rotate(
        quickPollsBetweenPosts,
        quickPollsSidebar,
      ),
      default: rotate(
        quickPollsAboveComments,
        quickPollsSidebar,
      ),
    };

    // @ts-ignore
    return rotateByRouteName[routeName] ?? rotateByRouteName.default;
  }

  const rotatePolls = getRotateByRouteName(routeName ?? 'default');

  const idsFetch = [
    ...new Set([
      ...rotatePolls.map((poll: any) => poll.id),
    ]),
  ].filter((id) => {
    return !quickPollStateIds.includes(id.toString());
  });

  Promise
    .all(
      // здесь можно попросить бек сделать один запрос
      // который бы возвращал опросы по списку
      idsFetch.map((id: number) => {
        const poll = rotatePolls.find((poll: any) => poll.id === id);
        return store.dispatch(FETCH_QUICK_POLL, poll);
      }),
    )
    .then(() => {
      rotatePolls.forEach((poll: any) => {
        commit(
          SET_QUICK_POLL_LOCATION,
          {
            quickPollId: poll.id,
            widgetId: poll.widgetId,
          },
        );
      });
    });
};

export default function ({ store, route }: Context): void {
  window.onNuxtReady(() => {
    task(store, route.name ?? null);
  });
};
