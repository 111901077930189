// не используется - LH-1552

import type { Context } from '@nuxt/types';
import type { Route } from 'vue-router';
import { isHealthPath } from '../router/utils';
import { isAnyRecipePage } from '~/router';
import { isPreviewPage } from '~/router/utils';
import {
  YA_METRIKA_ID,
  YA_METRIKA_ID2,
  YA_METRIKA_ID_RECIPE,
  YA_METRIKA_ID_HEALTH,
} from '~/constants/config';
import { Category } from '~/services/health/category';

export default ({ app: { store }, $logger, route }: Context) => {
  const log = $logger.withScope('🍇 yandexMetrika');
  const inited: number[] = [];

  initRouteSpecific(route);

  function initRouteSpecific (route: Route) {
    const currentArticle = store && store?.getters?.currentArticle;
    const healthCategory = currentArticle?.categories.filter((category: Category) => {
      return category.slug === 'health' || category.slug === 'sport';
    });
    if (healthCategory && healthCategory.length) {
      const healthInited = inited.includes(YA_METRIKA_ID_HEALTH);

      if (!healthInited) {
        initYaMetrika(YA_METRIKA_ID_HEALTH);
      }
    }

    // превью не считаем
    if (isPreviewPage(route.name)) { return; }

    if (!inited.includes(YA_METRIKA_ID)) {
      initYaMetrika(YA_METRIKA_ID);
    }

    if (!inited.includes(YA_METRIKA_ID2)) {
      initYaMetrika(YA_METRIKA_ID2);
    }

    const recipeInited = inited.includes(YA_METRIKA_ID_RECIPE);
    if (!recipeInited && isAnyRecipePage(route.name)) {
      initYaMetrika(YA_METRIKA_ID_RECIPE);
    }

    const healthInited = inited.includes(YA_METRIKA_ID_HEALTH);
    if (!healthInited && isHealthPath(route.path)) {
      initYaMetrika(YA_METRIKA_ID_HEALTH);
    }
  }

  function initYaMetrika (id: number) {
    log('init', id);
    try {
      window.ym(id, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
      inited.push(id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Yandex Metrika init error', error);
    }
  }
};
