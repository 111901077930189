// установка css переменной с корректным vw
function setVw () {
  const vw = document.documentElement.clientWidth / 100;
  document.documentElement.style.setProperty('--vw', `${vw}px`);
}

const task = () => {
  setVw();
  window.addEventListener('resize', setVw);
};

export default function (): void {
  window.onNuxtReady(task);
}
