import type { Context } from '@nuxt/types';

import { IWidgetsState } from '~/store/widgets';
import {
  FETCH_COMMENTS_COUNT,
  FETCH_VIEWS_COUNT,
  UPDATE_CURRENT_ARTICLES,
  UPDATE_USER,
  GET_AND_SET_FAVORITES,
  CLEAR_ALL_JOBS,
  GET_FOLDERS,
  FETCH_REACTIONS,
} from '~/constants';
import { isNumber } from '~/utils';

export const selectTaxonomyPostsWidgets = (widget: TWidget | WidgetInRecipes): boolean => {
  return Boolean((widget.type === 'taxonomy-posts') && widget?.items && widget.items.length < 3);
};

export const selectPostCollectionWidgets = (widget: TWidget | WidgetInRecipes): boolean => {
  return Boolean((widget.type === 'posts-collection') && Boolean(widget?.items));
};

// Получение списка id статей для виджетов с статьями
// selectWidget функция для определения нужных виджетов
export const getWidgetArticlesIds = (state: IWidgetsState, selectWidget: (widget: TWidget | WidgetInRecipes) => boolean): number[] => {
  const ids: number[] = [];

  const keys = Object.keys(state);
  keys.forEach((key) => {
    state[key as keyof IWidgetsState]?.forEach((widget: TWidget | WidgetInRecipes) => {
      if (selectWidget(widget)) {
        const currentIds = widget?.items?.reduce((acc: number[], article: { id: number }) => {
          return [...acc, article.id];
        }, []);
        ids.push(...currentIds);
      }
    });
  });

  return ids;
};

// Получение списка ид статей текущей страницы.
// currentArticles массив статей на архивной странице
// currentArticle объект статьи на внутренней
export const getCurrentPageArticlesIds = (currentArticles: IArticle[], currentArticle: ISingleArticle | null): number[] => {
  if (currentArticle) {
    return [currentArticle.id];
  }
  return currentArticles.reduce((acc: number[], item) => ([...acc, item.id]), []);
};

// Загрузка количества комментариев и просмотров.
// Количество просмотров загружается только у пользователей, авторизованных в вордпрессе.
// Для статей текущей страницы и для статей из необязательного параметра ids.
// Перед загрузкой проверяется то, что для данного ид не загружены данные ранее.
export const fetchCountOfCommentsAndViews = (store: Context['store'], ids: number[] = []) => {
  const { state, getters, dispatch } = store;
  const currentPageArticlesIds: number[] = getCurrentPageArticlesIds(getters.currentArticles, getters.currentArticle);

  let uniqueIds = [...new Set([...currentPageArticlesIds, ...ids])];
  uniqueIds = uniqueIds.filter(item => !isNumber(state.articles.commentsCount[String(item)]));

  if (uniqueIds.length) {
    dispatch(FETCH_COMMENTS_COUNT, uniqueIds);
    dispatch(FETCH_REACTIONS, uniqueIds);

    if (state.isAuthenticatedInWordpress) {
      dispatch(FETCH_VIEWS_COUNT, uniqueIds);
    }
  }
};

// Загрузка избранного
// Для статей текущей страницы
// Проверяется были ли данные по статьям текущей страницы загружены ранее
export const fetchFavorites = (store: Context['store']) => {
  const { state, getters, dispatch } = store;
  const currentPageArticlesIds: number[] = getCurrentPageArticlesIds(getters.currentArticles, getters.currentArticle);
  const taxonomyPostsIds: number[] = getWidgetArticlesIds(store.state.widgets, selectTaxonomyPostsWidgets);
  const postCollectionIds: number[] = getWidgetArticlesIds(store.state.widgets, selectPostCollectionWidgets);

  let uniqueIds = [...new Set([...currentPageArticlesIds, ...taxonomyPostsIds, ...postCollectionIds])];

  uniqueIds = uniqueIds.filter((id) => {
    const inFavorite = Boolean(
      state.articles.userRelated.favorites.find(
        (favorite: IFavoriteItem) => favorite.post_id === id,
      ),
    );
    return !inFavorite;
  });

  if (uniqueIds.length) {
    dispatch(GET_AND_SET_FAVORITES, {
      params: {
        post_ids: uniqueIds.join(),
      },
    });
  }
};

// Загрузка списка папок избранного
export const fetchFavoritesFolders = (store: Context['store']) => {
  const { state, dispatch } = store;

  dispatch(GET_FOLDERS)
    .then(() => {
      // отложенное добавление в избранное
      // срабатывает при нажатии на кнопку избранное незалогиненным пользователям
      if (state.jobs.length) {
        state.jobs.forEach((job: TJob) => job.handler());
        store.commit(CLEAR_ALL_JOBS);
      }
    });
};

const task = (store: Context['store']): void => {
  // Загрузка количества комментариев и просмотров для виджетов "посты таксономии" и для статей на текущей странице.
  const taxonomyPostsIds: number[] = getWidgetArticlesIds(store.state.widgets, selectTaxonomyPostsWidgets);
  const postCollectionIds: number[] = getWidgetArticlesIds(store.state.widgets, selectPostCollectionWidgets);
  fetchCountOfCommentsAndViews(store, [...taxonomyPostsIds, ...postCollectionIds]);

  // Обработчик на изменение параметра routePath.
  // В колбэке происходит загрузка количества просмотров и комментариев для новой страницы.
  store.watch(localState => localState.route.fullPath, (): void => {
    fetchCountOfCommentsAndViews(store);
    fetchFavorites(store);
  });

  // Обработчик на мутацию, которая вызывается при подгрузке статей на главной.
  // В колбэке происходит загрузка количества просмотров и комментариев для новых статей.
  store.subscribe((mutation) => {
    if (mutation.type === UPDATE_CURRENT_ARTICLES) {
      fetchCountOfCommentsAndViews(store);
      fetchFavorites(store);
    }
    if (mutation.type === UPDATE_USER) {
      fetchFavorites(store);
      fetchFavoritesFolders(store);
    }
  });
};

export default function ({ store }: Context): void {
  window.onNuxtReady(() => task(store));
};
