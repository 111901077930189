// не используется - LH-1552
import { getISODateString } from '@devhacker/shared/utils/date';
import type { Context } from '@nuxt/types';
import { isAnyRecipePage, isHealthPath } from '../router/utils';
import { GA_ID_RECIPE, GA_ID } from '../constants/config';

export default function ({ route, store, $sentry }: Context) {
  const gtag = (...args: any) => {
    const id = isAnyRecipePage(route.name) ? GA_ID_RECIPE : GA_ID;

    // @ts-ignore
    window[id] = window[id] || [];
    // @ts-ignore
    window[id].push(args);
  };

  if (isHealthPath(route.path)) { return; }

  if (route.name === 'single') {
    try {
      const { isTemplateCard, currentArticle: article } = store.getters;

      if (!article) {
        return;
      }

      const { categories, author: { name }, date, isAdvertising, isPromo, tags } = article as ISingleArticle;

      const ISOFirstPublishedDate = getISODateString(new Date(1000 * article.meta.lh_first_published_time));
      gtag('dimension11', ISOFirstPublishedDate);

      gtag('contentGroup1', name);

      const firstTopic = categories.find((item): boolean => item.url.includes('topics/'));
      const firstTopicTitle = firstTopic?.title ?? '';
      gtag('contentGroup2', firstTopicTitle);

      const firstStream = categories.find((item): boolean => item.url.includes('stream/'));
      const firstStreamTitle = firstStream?.title ?? '';
      gtag('contentGroup3', firstStreamTitle);

      const technicalTags: string[] = ['noad', 'nozen', 'hide', 'noturbo', 'noadsense'];
      const notTechnicalTags = tags.filter((item): boolean => !technicalTags.includes(item.title));
      const firstTagTitle = notTechnicalTags[0]?.title ?? '';
      gtag('contentGroup4', firstTagTitle);

      const partnerValueToSet = isAdvertising || isPromo ? 'Партнёрский материал' : null;
      gtag('dimension5', partnerValueToSet);

      const ISODate = getISODateString(new Date(1000 * date));
      gtag('dimension6', ISODate);

      const cardValueToSet = isTemplateCard ? 'Карточки' : null;
      gtag('dimension8', cardValueToSet);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      $sentry.captureException(error);
    }
  } else {
    ['contentGroup1', 'contentGroup2', 'contentGroup3', 'contentGroup4', 'dimension6', 'dimension5', 'dimension8', 'dimension11']
      .forEach((dimension: string) => gtag(dimension, null));
  }
  const { title } = store.getters.currentSeo as Seo;
  gtag('title', title);

  if (route.name === 'single') {
    const { isTemplateCard, currentArticle: article } = store.getters;

    if (!article) {
      return;
    }

    const { categories, author: { name }, date, isAdvertising, isPromo, tags } = article as ISingleArticle;

    const ISOFirstPublishedDate = getISODateString(new Date(1000 * article.meta.lh_first_published_time));

    gtag('set', 'linker', {
      domains: ['dev.lifehacker.ru', 'lifehacker.ru'],
    });

    gtag({ author: name });
    gtag({ lh_first_published_time: ISOFirstPublishedDate });

    const firstTopic = categories.find((item): boolean => item.url.includes('topics/'));
    const firstTopicTitle = firstTopic?.title ?? null;
    gtag({ category: firstTopicTitle });

    const firstStream = categories.find((item): boolean => item.url.includes('stream/'));
    const firstStreamTitle = firstStream?.title ?? null;
    gtag({ stream: firstStreamTitle });

    const technicalTags: string[] = ['noad', 'nozen', 'hide', 'noturbo', 'noadsense'];
    const notTechnicalTags = tags.filter((item): boolean => !technicalTags.includes(item.title));
    const firstTagTitle = notTechnicalTags[0]?.title ?? null;
    gtag({ tag: firstTagTitle });

    const partnerValueToSet = isAdvertising || isPromo ? 'Партнёрский материал' : null;
    gtag({ partner: partnerValueToSet });

    const ISODate = getISODateString(new Date(1000 * date));
    gtag({ 'published time': ISODate });

    const cardValueToSet = isTemplateCard ? 'Карточки' : null;
    gtag({ cards: cardValueToSet });
  }

  gtag({ page_title: title });
}
