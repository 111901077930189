import type { Context } from '@nuxt/types';
import { getSubId } from '~/utils';

const routesNeedCheck = [
  'profile-edit',
  'profile-settings',
  'profile-favorites',
  'profile-notifications',
];

export default ({ route }: Context) => {
  if (!route.name) { return; }
  if (!routesNeedCheck.includes(route.name)) { return; }

  const subId = getSubId();

  if (!subId || Number(route.params.userId) !== subId) {
    // eslint-disable-next-line no-console
    console.warn('redirect to /');
    location.href = '/';
  }
};
