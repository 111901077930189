import type { Context } from '@nuxt/types';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default ({ app: { store }, $logger, $sendCustomYandexMetrika }: Context) => {
  const log = $logger.withScope('🍇 yandexMetrika');

  try {
    setTimeout(() => {
      const userId = store?.state?.user?.integer_id;
      if (userId) {
        log('add user_id', userId);
        $sendCustomYandexMetrika({ user_id: userId });
      }
    }, 3000);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err, 'window.ym');
  }

  function linkClickHandler (event: any) {
    const currentSlug = getSlugFromUrl(window.location.href);
    const link = event.target.closest('a');
    if (!link) { return; }
    log('link click', link?.href);

    $sendCustomYandexMetrika({
      // * аналитик попросил сделать такую структуру
      outer_transition: { [currentSlug]: [link?.href] },
    });
  }

  document.addEventListener('click', linkClickHandler, { passive: false });
};
