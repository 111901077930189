import type { Context } from '@nuxt/types';
import type { AxiosResponse } from 'axios';

import {
  FETCH_USER,
  CLEAR_USER,
  CLEAR_USER_RELATED_DATA,
  CLEAR_FOLDERS,
  FETCH_USER_LOCATION,
} from '~/constants';
import { getAccessToken, isRequiresAuth, setAccessToken, _getSubId } from '~/utils';
import { normalizeUserData } from '~/libs/normalizeUserData';
import { connectPrivateChannel, fetchUnviewedNotificationsCount } from '~/components/Pusher/websockets-functions';

export default ({ store, route, redirect, query }: Context) => {
  function authenticatedCallback (e: MessageEvent | CustomEvent) {
    let accessToken = '';

    if (e.type === 'message') {
      const event = e as MessageEvent;
      // ждем строку, чтобы сделать JSON,
      // поэтому отфильтровываем все остальное
      if (typeof event.data !== 'string') {
        return;
      }

      // игнорим события метрики
      if (event.data.startsWith('__')) {
        return;
      }

      try {
        const data: IAccessTokenData = JSON.parse(event.data);
        accessToken = data.accessToken;
        setAccessToken(accessToken);
      } catch (error) {
      }
    } else if (e.type === 'googleOneTapAuth') {
      const event = e as CustomEvent;
      accessToken = event.detail.accessToken;

      setAccessToken(accessToken);
    } else {
      return;
    }

    if (!accessToken) {
      return;
    }

    try {
      const {
        state: {
          user,
        },
      } = store;

      const subId = _getSubId(accessToken);

      if (!accessToken || !subId) {
        return;
      }

      fetchUserData()
        .then(() => {
          if (isRequiresAuth(route)) {
            // eslint-disable-next-line no-console
            console.warn('redirect to /');
            return redirect({
              path: '/',
              query,
            });
          }

          if (!user) {
            connectPrivateChannel(store);
            fetchUnviewedNotificationsCount(store);
          }
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async function fetchUserData (): Promise<AxiosResponse> {
    const accessToken = getAccessToken();
    const subId = _getSubId(accessToken);
    const actions = store.getters['delayed-actions/delayedActions'];
    const response = await store
      .dispatch(FETCH_USER, { subId, createAxiosInstance: false })
      .catch((error: any) => {
        if (error.response.status === 403) {
          store.dispatch(CLEAR_USER);
          store.commit(CLEAR_USER_RELATED_DATA);
          store.commit(CLEAR_FOLDERS);

          if (isRequiresAuth(route)) {
            // eslint-disable-next-line no-console
            console.warn('redirect to /');
            return redirect({
              path: '/',
              query,
            });
          }
        }
      });

    if (store.state.user) {
      store.dispatch('talker/user/SET_USER_DATA_ACTION', normalizeUserData(store.state.user));
    }

    if (actions.loginAction) {
      actions.loginAction();
    }

    return response;
  }

  function Initialize (): void {
    store.dispatch(FETCH_USER_LOCATION);

    window.addEventListener('message', (e) => {
      authenticatedCallback(e);
    }, false);

    window.addEventListener('googleOneTapAuth', (e) => {
      authenticatedCallback(e as CustomEvent);
    }, false);

    const accessToken = getAccessToken();
    const subId = _getSubId(accessToken);

    // авторизация по существующей cookie
    if (accessToken && subId) {
      fetchUserData()
        .then(() => {
          connectPrivateChannel(store);
          fetchUnviewedNotificationsCount(store);
        });
    }
  }

  window.onNuxtReady(Initialize);
};
