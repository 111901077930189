import type { Context } from '@nuxt/types';

import {
  ADD_SIMILAR_RECIPES,
  ADD_RELATED_RECIPES,
  GET_CURRENT_RELATED_RECIPES,
  GET_CURRENT_SIMILAR_RECIPES,
  FETCH_VIEWS_COUNT,
  UPDATE_WORDPRESS_AUTHENTICATION,
} from '~/constants';
import { IWidgetsState } from '~/store/widgets';
import { isAnyRecipePage } from '~/router';

// Плагин для загрузки просмотров рецептов, которые отображаются в зонах виджетов. (см задачу CU-1fubaec)
//
// Алгоритм работы:
// 0  старт
// 1. если пользователь авторизован в админке
//    загрузить данные всех кастомных, related и similar виджетов
//    поставить обработчик на добавление рецептов similar и related и разлогин.
//        При добавлении новых виджетов загрузить просмотры для них.
//        При разлогине удалить обработчик и перейти в пункт 0
// 2. иначе
//    добавить обработчик на логин
//        в обработчике удалить обработчик и перейти в пункт 0

const task = (store: Context['store']) => {
  const fetchViews = (payload: number[]) => store.dispatch(FETCH_VIEWS_COUNT, payload);

  const isAuthenticatedInWordpress = (store.state as IRootState).isAuthenticatedInWordpress;
  if (isAuthenticatedInWordpress) {
    // 1
    const customWidgetsData = getCustomWidgetsRecipesId(store.state.widgets as IWidgetsState);
    const relatedWidgetsData = (store.getters[GET_CURRENT_RELATED_RECIPES] as WidgetRecipe[]).map(item => item.postId);
    const similarWidgetsData = (store.getters[GET_CURRENT_SIMILAR_RECIPES] as WidgetRecipe[]).map(item => item.postId);
    const payload = [...new Set([...customWidgetsData, ...relatedWidgetsData, ...similarWidgetsData])];
    fetchViews(payload);

    const handleAddWidgets = (payload: { list: WidgetRecipe[] }): void => {
      const ids = payload.list.map(item => item.postId);
      fetchViews(ids);
    };
    const unsubscribe = store.subscribe((mutation) => {
      switch (mutation.type) {
        case ADD_RELATED_RECIPES:
        case ADD_SIMILAR_RECIPES:
          handleAddWidgets(mutation.payload);
          break;
        case UPDATE_WORDPRESS_AUTHENTICATION:
          !mutation.payload.newState && unsubscribe();
          !mutation.payload.newState && task(store);
          break;
      }
    });
  } else {
    // 2
    const unsubscribe = store.subscribe((mutation) => {
      switch (mutation.type) {
        case UPDATE_WORDPRESS_AUTHENTICATION:
          mutation.payload.newState && task(store);
          mutation.payload.newState && unsubscribe();
          break;
      }
    });
  }
};

export default function ({ store, route }: Context): void {
  if (isAnyRecipePage(route.name)) {
    window.onNuxtReady(() => task(store));
  }
};

// Получение списка id виджетов с рецептами с типом custom
function getCustomWidgetsRecipesId (state: IWidgetsState): number[] {
  const ids: number[] = [];

  const keys = Object.keys(state);
  keys.forEach((key) => {
    state[key as keyof IWidgetsState]?.forEach((widget: TWidget | WidgetInRecipes) => {
      if (widget?.type === 'recipes-list' && 'list' in widget && widget.list === 'custom') {
        const currentIds = widget?.recipes?.reduce((acc: number[], item) => {
          return [...acc, item.postId];
        }, []);
        if (currentIds) {
          ids.push(...currentIds);
        }
      }
    });
  });

  return ids;
};
