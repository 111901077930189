import type { Context } from '@nuxt/types';
import { Route } from 'vue-router';
import { HOME_URL } from '~/constants/config';
import { IUtils } from '~/types';

// TODO: заменить на функции из utils и удалить плагин
class Utils implements IUtils {
  isHomeLink (link: string): boolean {
    return link.indexOf(HOME_URL) === 0;
  }

  getRelativeLink (link: string): string {
    return link.replace(HOME_URL, '');
  }

  // номер страницы в архивах
  getActivePage (route: Route): number {
    if (typeof route.query.page !== 'undefined') {
      const page = Array.isArray(route.query.page) ? route.query.page[0] : route.query.page;
      if (page !== null) {
        return parseInt(page);
      }
    }
    return 1;
  }
}

export default function (_ctx: Context, inject: any): any {
  const utils = new Utils();
  inject('utils', utils);
}
